import React, { useState, useEffect } from 'react';
import {
    Container,
    TextField,
    InputAdornment,
    MenuItem,
    Select,
    FormControl,
    Switch,
    Box,
    Typography,
    Autocomplete,
    IconButton,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { setHitOriginAddressApi } from '../state';
import { setHitDestinationApi } from '../state';
import PhoneISOSelect from './PhoneISO';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { validationRule } from '../constants/validationRule';
import axios from 'axios';
import { MuiTelInput } from 'mui-tel-input';

const validationSchema = Yup.object().shape({
    fullName: validationRule.FULLNAME,
    phoneNumber: validationRule.PHONE,
    email: validationRule.EMAIL,
    postCode: validationRule.POSTCODE,
    address1: validationRule.ADDRESS.required('Address is required'),
    address2: validationRule.ADDRESS,
    city: validationRule.CITY,
    country: validationRule.COUNTRY,
});

function AddressForm({ formTitle, apiUrl, onSuccessNavigateTo, dispatchAction, country, city, heading, address }) {
    const [cities, setCities] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const id = useSelector(state => state.auth.id);
    const [phoneISOs, setPhoneISOs] = useState([]);
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [entries, setEntries] = useState([]); // Holds the entries from the API
    const addAddress = useSelector(state => state.auth.hitOriginApi);
    const addDestinationAddress = useSelector(state => state.auth.hitDestinationApi);

    const formik = useFormik({
        initialValues: {
            fullName: address ? address.fullName : '',
            email: address ? address.email : '',
            receiveUpdates: address ? address.receiveUpdates : false,
            phoneNumber: address ? address.phoneNumber : '',
            phoneISO: address ? address.phoneISO : '',
            postCode: address ? address.postCode : '',
            address1: address ? address.address1 : '',
            address2: address ? address.address2 : '',
            state: address ? address.state : '',
            type: address ? address.type : '',
            city: city,
            country: country,
        },
        validationSchema: validationSchema,
    });

    useEffect(() => {
        // fetchPhoneISOs();
        fetchCities();
    }, []);

    // Doesn't seem to work (server not responding)
    useEffect(() => {
        if (country === 'United Kingdom' && formik.values.postCode) {
            const fetchAddress = async () => {
                try {
                    const apiURL = `https://ws1.postcodesoftware.co.uk/lookup.asmx/getAddress?account=2875&password=ctl74pai&postcode=${encodeURIComponent(
                        formik.values.postCode
                    )}&api_key=WsFh-kMOz-aNLu-biyD`;

                    // did not change to axios as i can't get any response
                    const response = await fetch(apiURL);

                    const xmlData = await response.text(); // Get the response as text
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(xmlData, 'application/xml');

                    // Extract data using DOM methods
                    const address1 = xmlDoc.getElementsByTagName('Address1')[0].textContent;
                    const town = xmlDoc.getElementsByTagName('Town')[0].textContent;
                    const county = xmlDoc.getElementsByTagName('County')[0].textContent;
                    const postcode = xmlDoc.getElementsByTagName('Postcode')[0].textContent;
                    const premiseData = xmlDoc.getElementsByTagName('PremiseData')[0].textContent;
                    let selectedCity = county; // Default to county

                    if (!cities.includes(county)) {
                        selectedCity = town; // If county is not in the cities array, fall back to town
                    }

                    await formik.setFieldValue('city', selectedCity);
                    const entriesArray = convertStringToArray(premiseData, address1);

                    setEntries(entriesArray);
                    console.log('Formatted Entries:', entriesArray);

                    console.log('Fetched address data:', { address1, town, county, postcode, premiseData });
                    // Here you could dispatch Redux actions to update the app state or handle the data directly
                } catch (error) {
                    console.error('Failed to fetch address:', error);
                }
            };

            fetchAddress();
        }
    }, [formik.values.postCode, country]);

    function formatEntries(entries, street) {
        return entries.map(entry => {
            // Use a regex to split by '||' or '|' (escaping | because it's special in regex)
            const parts = entry.split(/(?:\|\||\|)/); // Use non-capturing group to avoid capturing delimiters in the results
            const name = parts[0].trim(); // Name part, trimmed to remove any extra whitespace
            const number = parts[1] ? parts[1].trim() : ''; // Number part, check if exists

            // Return the formatted string conditionally including the name only if it exists
            if (name) {
                return `${name}, ${number} ${street}`; // Name, number, and street
            } else {
                return `${number} ${street}`; // Only number and street if name is empty
            }
        });
    }

    function convertStringToArray(data, street) {
        let entries = data.split(';');
        entries = entries.filter(entry => entry.trim() !== '').map(entry => entry.trim());
        return formatEntries(entries, street); // Call formatEntries to further process each entry
    }

    // const fetchPhoneISOs = async () => {
    //     try {
    //         const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/order/getPhoneISOs`);

    //         const matchingISO = data.find(iso => iso.country === country);

    //         if (matchingISO) {
    //             await formik.setFieldValue('phoneISO', matchingISO.text);
    //         } else {
    //             await formik.setFieldValue('phoneISO', '+44');
    //         }

    //         setPhoneISOs(data);
    //     } catch (error) {
    //         console.error('Error fetching phone ISOs:', error);
    //     }
    // };

    const fetchCities = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/order/cities`);

            if (country === 'Pakistan') {
                setCities(data.pakistanCities);
            } else {
                setCities(data.ukCities);
            }
        } catch (error) {
            console.error('Failed to fetch cities:', error);
        }
    };

    const resetFormData = () => {
        formik.resetForm();
    };

    const handleAddAddress = async () => {
        try {
            formik.setTouched({
              fullName: true,
              email: true,
              receiveUpdates: true,
              phoneNumber: true,
              phoneISO: true,
              postCode: true,
              address1: true,
              address2: true,
              city: true,
              state: true,
              type: true,
              country: true,
            });

            if (!formik.isValid) {
                return;
            }

            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}${apiUrl}/${id}`, formik.values);

            dispatchAction(formik.values);
            navigate(onSuccessNavigateTo);

            console.log('Address added successfully:', data);

            resetFormData();
        } catch (error) {
            console.error('Error adding address:', error);
        }
    };

    if (addAddress) {
        handleAddAddress();
        dispatch(setHitOriginAddressApi({ hitOriginApi: false }));
    }

    if (addDestinationAddress) {
        handleAddAddress();
        dispatch(setHitDestinationApi({ hitDestinationApi: false }));
    }

    return (
        <Container maxWidth="lg" sx={{ marginTop: 4 }}>
            <Typography variant="h4" sx={{ fontWeight: 600, color: '#2f3135' }} gutterBottom>
                {formTitle}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 400, color: '#2f3135' }} gutterBottom>
                {heading}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="left" gap={1} flex={1} marginRight={2}>
                <FormControl
                    fullWidth
                    sx={{
                        transition: 'box-shadow 0.3s ease-in-out',
                        '&:hover': {
                            boxShadow: '0 0 5px #ec1c24',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                        },
                    }}
                >
                    <Select
                        id="type"
                        name={'type'}
                        value={formik.values.type}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.type && Boolean(formik.errors.type)}
                        helperText={formik.touched.type && formik.errors.type}
                        placeholder={'Please select'}
                        displayEmpty
                    >
                        <MenuItem value="House">House</MenuItem>
                        <MenuItem value="Appartment">Appartment</MenuItem>
                        <MenuItem value="Business">Business</MenuItem>
                        <MenuItem value="University Accommodation">University Accommodation</MenuItem>
                        <MenuItem value="Hotel">Hotel</MenuItem>
                    </Select>
                </FormControl>

                {formik.values.type && (
                    <>
                        <Box display="flex" flexDirection="column" alignItems="left" gap={1} flex={1}>
                            <Typography variant="h5" sx={{ minWidth: '6rem', fontWeight: '400' }}>
                                Full Name
                            </Typography>
                            <FormControl fullWidth>
                                <TextField
                                    id="fullName"
                                    name={'fullName'}
                                    value={formik.values.fullName}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                                    helperText={formik.touched.fullName && formik.errors.fullName}
                                    fullWidth
                                    placeholder="Name"
                                    variant="outlined"
                                    sx={{
                                        '.MuiOutlinedInput-root': {
                                            // Target the root of the input element for hover effect
                                            transition: 'box-shadow 0.3s ease-in-out',
                                            '&:hover': {
                                                boxShadow: '0 0 5px #ec1c24',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: 'none', // This affects the static state; ensure it aligns with your design requirements
                                                },
                                            },
                                            '&.Mui-error:hover': {
                                                boxShadow: '0 0 5px red', // Optional: Differentiate the hover effect for error state
                                            },
                                        },
                                    }}
                                />
                            </FormControl>

                            <Typography variant="h5" sx={{ minWidth: '6rem', fontWeight: '400' }}>
                                Email
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: isMobileOrTablet ? 'column' : 'row',
                                    alignItems: 'left',
                                    gap: 3,
                                }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        id="email"
                                        name={'email'}
                                        value={formik.values.email}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        fullWidth
                                        placeholder="Email"
                                        variant="outlined"
                                        sx={{
                                            '.MuiOutlinedInput-root': {
                                                // Target the root of the input element for hover effect
                                                transition: 'box-shadow 0.3s ease-in-out',
                                                '&:hover': {
                                                    boxShadow: '0 0 5px #ec1c24',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: 'none', // This affects the static state; ensure it aligns with your design requirements
                                                    },
                                                },
                                                '&.Mui-error:hover': {
                                                    boxShadow: '0 0 5px red', // Optional: Differentiate the hover effect for error state
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {!isMobileOrTablet && (
                                                        <>
                                                            Receive Updates
                                                            <Switch
                                                                checked={formik.values.receiveUpdates}
                                                                onChange={e =>
                                                                    formik.setFieldValue(
                                                                        'receiveUpdates',
                                                                        e.target.checked
                                                                    )
                                                                }
                                                                name="receiveUpdates"
                                                                sx={{
                                                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                                                        color: '#ec1c24',
                                                                    },
                                                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                                                                        {
                                                                            backgroundColor: '#ec1c24',
                                                                        },
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                                {isMobileOrTablet && (
                                    <>
                                        <Box sx={{ mb: 2 }}>
                                            <InputAdornment position="start">
                                                <Typography>Receive Updates</Typography>
                                                <Switch
                                                    checked={true}
                                                    name="receiveUpdates"
                                                    sx={{
                                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                                            color: '#ec1c24',
                                                        },
                                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                            backgroundColor: '#ec1c24',
                                                        },
                                                    }}
                                                />
                                            </InputAdornment>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>

                        <Typography variant="h5" sx={{ fontWeight: '400' }}>
                            Phone Number
                        </Typography>
                        <Box display="flex" flexDirection="row" alignItems="center" gap={0} flex={1} marginRight={0}>
                            <FormControl
                                fullWidth
                                sx={{
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    '&:hover': {
                                        boxShadow: '0 0 5px #ec1c24',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            >
                                <MuiTelInput
                                    id="phoneNumber"
                                    name={'phoneNumber'}
                                    defaultCountry={'GB'}
                                    value={formik.values.phoneNumber}
                                    onBlur={formik.handleBlur}
                                    onChange={phone => formik.setFieldValue('phoneNumber', phone)}
                                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                    fullWidth
                                    placeholder="Phone Number"
                                    variant="outlined"
                                    sx={{
                                        transition: 'box-shadow 0.3s ease-in-out',
                                        width: '100%', // Adjust width to be smaller
                                        '& .MuiOutlinedInput-input': {
                                            padding: '10px 14px', // Adjust padding to reduce height
                                        },
                                    }}
                                />
                            </FormControl>
                        </Box>

                        <Box display="flex" flexDirection="column" alignItems="left" gap={1} flex={1}>
                            <Typography variant="h5" sx={{ minWidth: '6rem', fontWeight: '400' }}>
                                Post Code
                            </Typography>
                            <FormControl fullWidth>
                                <TextField
                                    id="postCode"
                                    name={'postCode'}
                                    value={formik.values.postCode}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.postCode && Boolean(formik.errors.postCode)}
                                    helperText={formik.touched.postCode && formik.errors.postCode}
                                    fullWidth
                                    placeholder="Post Code"
                                    variant="outlined"
                                    sx={{
                                        '.MuiOutlinedInput-root': {
                                            // Target the root of the input element for hover effect
                                            transition: 'box-shadow 0.3s ease-in-out',
                                            '&:hover': {
                                                boxShadow: '0 0 5px #ec1c24',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: 'none', // This affects the static state; ensure it aligns with your design requirements
                                                },
                                            },
                                            '&.Mui-error:hover': {
                                                boxShadow: '0 0 5px red', // Optional: Differentiate the hover effect for error state
                                            },
                                        },
                                    }}
                                />
                            </FormControl>
                            <Typography variant="h5" sx={{ minWidth: '6rem', fontWeight: '400' }}>
                                Address Line 1
                            </Typography>
                            <Autocomplete
                                freeSolo // Allows typing freely in the input, not just selecting from the dropdown
                                id="postCode-autocomplete"
                                disableClearable
                                name={'address1'}
                                value={formik.values.address1} // The current value of the postcode
                                onChange={(e, value) => formik.setFieldValue('address1', value)}
                                options={entries} // The entries array from the API
                                // onChange={(event, newValue, reason) => {
                                //     setAddressLine1(newValue); // Update Address Line 1 when an item is selected
                                // }}
                                // onInputChange={(event, newInputValue, reason) => {
                                //     setAddressLine1(newInputValue); // Update postCode state as the input changes
                                // }}
                                sx={{
                                    '.MuiOutlinedInput-root': {
                                        // Target the root of the input element for hover effect
                                        transition: 'box-shadow 0.3s ease-in-out',
                                        '&:hover': {
                                            boxShadow: '0 0 5px #ec1c24',
                                            '.MuiOutlinedInput-notchedOutline': {
                                                border: 'none', // This affects the static state; ensure it aligns with your design requirements
                                            },
                                        },
                                        '&.Mui-error:hover': {
                                            boxShadow: '0 0 5px red', // Optional: Differentiate the hover effect for error state
                                        },
                                    },
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="e.g, Street 1, House no. 123"
                                        error={!!formik.errors.address1}
                                        helperText={formik.errors.address1}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'text',
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <ArrowDropDownIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Typography variant="h5" sx={{ minWidth: '6rem', fontWeight: '400' }}>
                                Address Line 2{' '}
                                <Typography
                                    component="span"
                                    variant="h6"
                                    sx={{
                                        fontWeight: '300',
                                        color: 'grey',
                                        fontSize: '0.8rem', // Adjust the size as needed
                                    }}
                                >
                                    (optional)
                                </Typography>
                            </Typography>

                            <FormControl fullWidth>
                                <TextField
                                    id="addressLine2"
                                    name={'addressLine2'}
                                    value={formik.values.address2}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.address2 && Boolean(formik.errors.address2)}
                                    helperText={formik.touched.address2 && formik.errors.address2}
                                    fullWidth
                                    placeholder=""
                                    variant="outlined"
                                    sx={{
                                        '.MuiOutlinedInput-root': {
                                            // Target the root of the input element for hover effect
                                            transition: 'box-shadow 0.3s ease-in-out',
                                            '&:hover': {
                                                boxShadow: '0 0 5px #ec1c24',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: 'none', // This affects the static state; ensure it aligns with your design requirements
                                                },
                                            },
                                            '&.Mui-error:hover': {
                                                boxShadow: '0 0 5px red', // Optional: Differentiate the hover effect for error state
                                            },
                                        },
                                    }}
                                />
                            </FormControl>

                            <Typography variant="h5" sx={{ minWidth: '6rem', fontWeight: '400' }}>
                                City
                            </Typography>
                            <FormControl
                                fullWidth
                                sx={{
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    '&:hover': {
                                        boxShadow: '0 0 5px #ec1c24',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            >
                                {/* <Select
                                    name={'city'}
                                    value={formik.values.city}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.city)}
                                    helperText={formik.errors.city}
                                    id="fromCity"
                                    displayEmpty
                                    placeholder={'Select city'}
                                >
                                    {cities.map(city => (
                                        <MenuItem key={city} value={city}>
                                            {city}
                                        </MenuItem>
                                    ))}
                                </Select> */}
                                 <TextField
                                    id="city"
                                    name={'city'}
                                    value={formik.values.city}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.city && Boolean(formik.errors.country)}
                                    helperText={formik.touched.city && formik.errors.country}
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </FormControl>

                            <Typography variant="h5" sx={{ minWidth: '6rem', fontWeight: '400' }}>
                                Country
                            </Typography>
                            <FormControl
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#528aae',
                                    },
                                }}
                            >
                                <TextField
                                    id="country"
                                    name={'country'}
                                    value={formik.values.country}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.country && Boolean(formik.errors.country)}
                                    helperText={formik.touched.country && formik.errors.country}
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </>
                )}
            </Box>
        </Container>
    );
}

export default AddressForm;
