import * as Yup from 'yup';
import { validationRule } from './validationRule';

export const customerLoginMode = {
    LOGIN: 'LOGIN',
    REGISTER: 'REGISTER',
    RESET: 'RESET',
};

export const customerLoginModeValidation = {
    LOGIN: Yup.object().shape({
        password: validationRule.PASSWORD,
        email: validationRule.EMAIL,
    }),
    REGISTER: Yup.object().shape({
        fullName: validationRule.FULLNAME,
        email: validationRule.EMAIL,
        phone: validationRule.PHONE,
        password: validationRule.PASSWORD,
    }),
    RESET: Yup.object().shape({
        email: validationRule.EMAIL,
    }),
};
