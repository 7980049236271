import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material';

export const EmailVerificationPage = () => {
    const { token } = useParams();
    const [loading, setLoading] = useState(true);
    const [isTokenValid, setIsTokenValid] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/customer/validate-email`, { token })
                .then(res => {
                    setIsTokenValid(res.data.isTokenValid);
                })
                .catch(error => {
                    setErrorMessage(error.response.data.message);
                })
                .finally(() => setLoading(false));
        }
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'top',
                pt: '12%',
                minHeight: '100vh',
            }}
        >
            {loading ? (
                <CircularProgress sx={{ mt: '2.5em' }} color={'error'} />
            ) : (
                <Card
                    variant="outlined"
                    sx={{
                        padding: 3,
                        maxWidth: 400,
                        height: 'fit-content',
                        textAlign: 'center',
                        borderColor: '#ec1c24',
                        borderWidth: 2,
                        backgroundColor: 'white',
                    }}
                >
                    {isTokenValid ? (
                        <>
                            <Typography variant="h5" gutterBottom>
                                Your email has been successfully verified. Please proceed to login.
                            </Typography>
                        </>
                    ) : (
                        <>
                            {errorMessage && (
                                <Typography variant="h5" color={'error'} gutterBottom>
                                    {errorMessage}
                                </Typography>
                            )}
                        </>
                    )}
                    <Button
                        onClick={() => navigate('/customer')}
                        variant={'contained'}
                        sx={{
                            backgroundColor: '#ec1c24',
                            color: 'white',
                            '&:hover': { backgroundColor: '#b7181f' },
                            mt: 2,
                            mb: 1,
                        }}
                        fullWidth
                    >
                        Proceed to login
                    </Button>
                </Card>
            )}
        </Box>
    );
};
