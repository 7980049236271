import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ConfirmationBox from './confirmationBox';
import Payment from './Payment';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';

function ConfirmOrder() {
    const user = useSelector(state => state.auth.user);

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

    return (
        <Container maxWidth="lg" style={{ marginTop: '20px' }}>
            <Typography variant="h3" sx={{ fontWeight: '600', color: '#2f3135' }} gutterBottom>
                Confirm your Order
            </Typography>
            <ConfirmationBox />
            {user && <Payment stripePromise={stripePromise} />}
        </Container>
    );
}

export default ConfirmOrder;
