 

import React from 'react';
import { Box, Typography } from '@mui/material';
import ProhibitedItems from '../../components/ProhibitedItems';
 
 
const Prohibited = () => {
  
  return (
    <Box id="aboutSection" sx={{mt: "5vh"}}>
      <ProhibitedItems  />
      </Box>
  );
};

export default Prohibited;
