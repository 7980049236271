import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import {
    Box,
    Typography,
    TextField,
    InputAdornment,
    Button,
    FormControl,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function BannerCarousel() {
    const [consignmentNo, setConsignmentNo] = useState('');
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isTablet = useMediaQuery(theme.breakpoints.down('768px'));

    const handleTrackClick = () => {
        if (consignmentNo) {
            navigate(`/tracking/${consignmentNo}`);
        }
    };

    return (
        <>
            <Box
                sx={{
                    marginTop: '1vh',
                    minWidth: '100vw',
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant={isMobileOrTablet ? 'h2' : 'h1'}
                    sx={{
                        fontSize: isMobileOrTablet ? '16px' : '36px',
                        textAlign: 'center',
                        fontWeight: 700,
                        position: 'absolute',
                        zIndex: 2,
                        top: isMobileOrTablet ? '10vh' : '60vh',
                        color: 'white',
                    }}
                >
                    Avoid Extra Luggage Fees with TCS Express Boxes
                </Typography>
                <Box
                    id="homeSection"
                    sx={{
                        position: 'absolute',
                        zIndex: 2,
                        top: isMobileOrTablet ? '23vh' : '70vh',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: isMobileOrTablet ? '8vh' : '5vh',
                    }}
                >
                    <FormControl
                        sx={{
                            background: 'transparent',
                            maxWidth: isMobileOrTablet ? '95%' : '50%',
                            width: '100%',
                            borderRadius: '10px',
                            transition: 'box-shadow 0.3s ease-in-out',
                            '&:hover': {
                                boxShadow: '0 0 5px #ec1c24',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            },
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Enter Consignment Number"
                            value={consignmentNo}
                            onChange={e => setConsignmentNo(e.target.value)}
                            sx={{
                                backgroundColor: isMobileOrTablet ? 'rgba(255, 255, 255, 0.75)' : 'white',

                                borderRadius: '10px',
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                backgroundColor: 'white',

                                                borderRadius: '10px',
                                                textTransform: 'none',
                                                fontSize: isMobileOrTablet ? '0.75rem' : '1rem',
                                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',

                                                color: 'white',
                                                backgroundColor: '#ec1c24',
                                                boxShadow: '0 0 5px #ec1c24',
                                            }}
                                            onClick={handleTrackClick}
                                        >
                                            Track
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </Box>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: false,
                    }}
                    navigation={false}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                    style={{
                        position: 'relative',
                        height: '100%',
                    }}
                >
                    <SwiperSlide>
                        <Box
                            sx={{
                                minWidth: '70vw',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                                height: '100%',
                            }}
                        >
                            <img
                                src="/assets/landscapeboxes.jpg"
                                alt=""
                                style={{
                                    width: isMobileOrTablet ? '100vw' : '80vw',
                                    height: isMobileOrTablet ? '30dvh' : '85dvh',
                                    maxWidth: 1300,
                                    objectFit: 'cover',
                                    objectPosition: 'center 80%',
                                    margin: 'auto',
                                    filter: 'brightness(60%)',
                                }}
                            />
                        </Box>
                    </SwiperSlide>
                </Swiper>
            </Box>
        </>
    );
}
