// import React, { useState, useEffect } from 'react';
// import { Box, Button } from '@mui/material';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';

// export default function VideoPlayer() {
//   const [play, setPlay] = useState(false);

//   useEffect(() => {
//     // Attempt to autoplay video on load
//     if (play) {
//       document.getElementById('video').play().catch(error => console.log("Autoplay was prevented."));
//     }
//   }, [play]);

//   const handlePlay = () => {
//     setPlay(true);
//     const videoElement = document.getElementById('video');
//     if (videoElement) {
//       videoElement.style.display = 'block'; // Show the video
//       videoElement.play(); // Play the video
//     }
//   };

//   return (
//     <Box sx={{ position: 'relative', width: '50%' }}>
//       <video
//         id="video"
//         src="assets/tcsVideo.mp4"
//         controls
//         width="100%"
//         style={{ display: play ? 'block' : 'none' }}
//       >
//         Your browser does not support the video tag.
//       </video>
//       {!play && (
//         <Button
//           sx={{
//             position: 'absolute',
//             top: '50%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             zIndex: 1,
//             color: '#fff',
//             backgroundColor: 'rgba(0, 0, 0, 0.5)',
//             '&:hover': {
//               backgroundColor: 'rgba(0, 0, 0, 0.7)',
//             },
//           }}
//           onClick={handlePlay}
//         >
//           <PlayArrowIcon sx={{ fontSize: 50 }} />
//         </Button>
//       )}
//     </Box>
//   );
// }

import React, { useState } from 'react';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

export default function VideoPlayer() {
    const [play, setPlay] = useState(false);
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

    const handlePlay = () => {
        setPlay(true);
        const videoElement = document.getElementById('video');
        if (videoElement) {
            videoElement.style.display = 'block'; // Show the video
            videoElement.play(); // Play the video
        }
    };

    return (
        <Box sx={{ position: 'relative', width: isMobileOrTablet ? '100%' : '50%' }}>
            <video
                id="video"
                src="assets/video.mp4"
                controls
                width="100%"
                poster="/assets/landscapeboxes.jpg"
                style={{ display: 'block' }}
            >
                Your browser does not support the video tag.
            </video>
            {/* {!isMobileOrTablet && !play && (
        <Box
          sx={{
            mt:"-14vh",
            minHeight:"350px",
            minWidth:"300px",
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: 'url(/assets/mobile1.jpg)', // Direct path from the public directory
            backgroundSize: 'contain', // Adjust this to 'contain' for no stretching
            backgroundRepeat: 'no-repeat', // Prevents the image from repeating
            backgroundPosition: 'center', // Keeps the image centered
            cursor: 'pointer',
            backgroundColor: 'black', // Optional: Fills letterboxing with black
          }}
          onClick={handlePlay}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              },
              borderRadius: '50%',
            }}
          >
            <PlayCircleOutlineIcon sx={{ fontSize: 60, color: '#000' }} />
          </Button>
        </Box>
      )} */}
        </Box>
    );
}
