import AddressForm from './Address';
import { useDispatch, useSelector } from 'react-redux';
import { setDestinationAddress } from '../state';

function DestinationAddressPage() {
    const dispatch = useDispatch();
    const destination = useSelector(state => state.auth.destination);
    const destinationCity = useSelector(state => state.auth.destinationCity);
    const address = useSelector(state => state.auth.destinationAddress);

    const handleDispatchAction = AddressData => {
        dispatch(setDestinationAddress({ destinationAddress: AddressData }));
    };

    return (
        <AddressForm
            formTitle="Where will we deliver your items?"
            apiUrl="/order/addDestinationAddress"
            onSuccessNavigateTo="/order/reviewAddress"
            country={destination}
            city={destinationCity}
            dispatchAction={handleDispatchAction}
            heading="Destination Address"
            address={address}
        />
    );
}

export default DestinationAddressPage;
