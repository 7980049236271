// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
} */
#customReviews {
  max-width: 100%;
 
}

#customReviews .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

#customReviews .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

#customReviews .swiper-slide img {
  display: block;
  width: 100%;
}

@media (max-width: 375px) {  
  #customReviews .swiper {
    width: 100%;
  }
}

`, "",{"version":3,"sources":["webpack://./src/scenes/express/styles.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAgCG;AACH;EACE,eAAe;;AAEjB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,2BAA2B;EAC3B,sBAAsB;EACtB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":["/* #app { height: 100% }\nhtml,\nbody {\n  position: relative;\n  height: 100%;\n}\n\nbody {\n  background: #eee;\n  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;\n  font-size: 14px;\n  color: #000;\n  margin: 0;\n  padding: 0;\n}\n\n.swiper {\n  width: 100%;\n  padding-top: 50px;\n  padding-bottom: 50px;\n}\n\n.swiper-slide {\n  background-position: center;\n  background-size: cover;\n  width: 300px;\n  height: 300px;\n}\n\n.swiper-slide img {\n  display: block;\n  width: 100%;\n} */\n#customReviews {\n  max-width: 100%;\n \n}\n\n#customReviews .swiper {\n  width: 100%;\n  padding-top: 50px;\n  padding-bottom: 50px;\n}\n\n#customReviews .swiper-slide {\n  background-position: center;\n  background-size: cover;\n  width: 300px;\n  height: 300px;\n}\n\n#customReviews .swiper-slide img {\n  display: block;\n  width: 100%;\n}\n\n@media (max-width: 375px) {  \n  #customReviews .swiper {\n    width: 100%;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
