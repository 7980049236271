import * as Yup from 'yup';
import { matchIsValidTel } from 'mui-tel-input';

export const validationRule = {
    FULLNAME: Yup.string()
        .min(2, 'Full name must be at lease 2 characters')
        .matches(/^[a-zA-Z\s]+$/, 'Full name must only contain letters and spaces')
        .required('Full name is required'),
    EMAIL: Yup.string().email('Invalid email address').required('Email is required'),
    PASSWORD: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    ADDRESS: Yup.string().min(4, 'Address must be at least 4 characters long'),
    CITY: Yup.string().required('City is required').min(2, 'City must be at least 2 characters long'),
    COUNTRY: Yup.string().required('Country is required'),
    PHONE: Yup.string()
        .required('Phone number is required')
        .test('is-valid-phone', 'Invalid phone number', value => matchIsValidTel(value)),
    TRACKING_NUMBER: Yup.string().required('Tracking number is required').min(4, 'Invalid tracking number'),
    MESSAGE: Yup.string().required('Message is required').max(1000, 'Maximum number of characters exceeded (1000)'),
    PHONE_ISO: Yup.string().required('Phone ISO is required'),
    PHONE_WITHOUT_ISO: Yup.string().required().min(5, 'Invalid phone number').max(10, 'Invalid phone number'),
    POSTCODE: Yup.string().required('Post code is required'),
    REQUIRED: Yup.string().required('This field is required'),
};
