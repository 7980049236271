import React, { useState } from 'react';
import {
    Typography,
    Button,
    FormControl,
    TextField,
    Box,
    Container,
    useTheme,
    useMediaQuery,
    Alert,
    Snackbar,
} from '@mui/material';
import { validationRule } from '../../constants/validationRule';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import { MuiTelInput } from 'mui-tel-input';

const validationSchema = Yup.object().shape({
    name: validationRule.FULLNAME,
    phone: validationRule.PHONE,
    email: validationRule.EMAIL,
    country: validationRule.COUNTRY,
    tracking: validationRule.TRACKING_NUMBER,
    message: validationRule.MESSAGE,
});

function GetInTouch() {
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const showNotification = message => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const greyBackgroundStyle = {
        width: '100%',
        paddingTop: '20px',
        paddingBottom: '20px',
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            email: '',
            country: '',
            tracking: '',
            message: '',
        },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: () => {
            handleInquiryForm().then(() => showNotification('Request submitted successfully!'));
        },
    });

    const handleInquiryForm = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/inquiry/new`, formik.values);
            formik.resetForm();
        } catch (error) {
            console.error('Error sending inquiry:', error);
        }
    };

    return (
        <Box
            style={{
                ...greyBackgroundStyle,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
                minHeight: '50vh',
            }}
        >
            <Container
                maxWidth="lg"
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            >
                <Box
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 1,
                    }}
                >
                    {/* Query and Get in Touch Box */}
                    {/* <Box style={halfWidthStyle}> */}

                    <Typography variant="h6" style={{ fontWeight: 300, color: '#2f3135', textAlign: 'center' }}>
                        {' '}
                        {/* Adjust marginBottom as needed */}
                        Our team is happy to answer all your queries.
                    </Typography>
                    <Typography
                        variant="h6"
                        style={{ fontWeight: 300, marginBottom: '0vh', color: '#2f3135', textAlign: 'center' }}
                    >
                        {' '}
                        {/* Adjust marginBottom as needed */}
                        Fill out the form and we'll be in touch as soon as possible.
                    </Typography>
                    {/* </Box>
                     */}

                    {/* Form Fields Box */}
                    <form style={{ minWidth: '60%' }} onSubmit={formik.handleSubmit}>
                        <Box display="flex" flexDirection="column" alignItems="left" gap={1} flex={1}>
                            {/* Name */}
                            <Typography variant="h6" fontWeight="400" textAlign={'left'}>
                                Name
                            </Typography>
                            <FormControl
                                fullWidth
                                sx={{
                                    minWidth: isMobileOrTablet ? '80vw' : 0,
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    '&:hover': {
                                        boxShadow: '0 0 5px #ec1c24',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            >
                                <TextField
                                    id="Name"
                                    name={'name'}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    fullWidth
                                    placeholder="Name"
                                    variant="outlined"
                                    sx={{
                                        transition: 'box-shadow 0.3s ease-in-out',
                                        width: '100%', // Adjust width to be smaller
                                        '& .MuiOutlinedInput-input': {
                                            padding: '10px 14px', // Adjust padding to reduce height
                                        },
                                    }}
                                />
                            </FormControl>
                            {/* Tracking */}
                            <Typography variant="h6" fontWeight="400" textAlign={'left'}>
                                Tracking Number
                            </Typography>
                            <FormControl
                                fullWidth
                                sx={{
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    '&:hover': {
                                        boxShadow: '0 0 5px #ec1c24',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            >
                                <TextField
                                    id="tracking"
                                    name={'tracking'}
                                    value={formik.values.tracking}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.tracking && Boolean(formik.errors.tracking)}
                                    helperText={formik.touched.tracking && formik.errors.tracking}
                                    fullWidth
                                    placeholder="Tracking Number"
                                    variant="outlined"
                                    sx={{
                                        transition: 'box-shadow 0.3s ease-in-out',
                                        width: '100%', // Adjust width to be smaller
                                        '& .MuiOutlinedInput-input': {
                                            padding: '10px 14px', // Adjust padding to reduce height
                                        },
                                    }}
                                />
                            </FormControl>
                            {/* Email */}
                            <Typography variant="h6" fontWeight="400" textAlign={'left'}>
                                Email
                            </Typography>
                            <FormControl
                                fullWidth
                                sx={{
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    '&:hover': {
                                        boxShadow: '0 0 5px #ec1c24',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            >
                                <TextField
                                    id="email"
                                    name={'email'}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    fullWidth
                                    placeholder="Email"
                                    variant="outlined"
                                    sx={{
                                        transition: 'box-shadow 0.3s ease-in-out',
                                        width: '100%', // Adjust width to be smaller
                                        '& .MuiOutlinedInput-input': {
                                            padding: '10px 14px', // Adjust padding to reduce height
                                        },
                                    }}
                                />
                            </FormControl>
                            {/* Phone */}
                            <Typography variant="h6" fontWeight="400" textAlign={'left'}>
                                Phone Number
                            </Typography>
                            <FormControl
                                fullWidth
                                sx={{
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    '&:hover': {
                                        boxShadow: '0 0 5px #ec1c24',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            >
                                <MuiTelInput
                                    id="phone"
                                    name={'phone'}
                                    defaultCountry={'GB'}
                                    value={formik.values.phone}
                                    onBlur={formik.handleBlur}
                                    onChange={phone => formik.setFieldValue('phone', phone)}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                    fullWidth
                                    placeholder="Phone Number"
                                    variant="outlined"
                                    sx={{
                                        transition: 'box-shadow 0.3s ease-in-out',
                                        width: '100%', // Adjust width to be smaller
                                        '& .MuiOutlinedInput-input': {
                                            padding: '10px 14px', // Adjust padding to reduce height
                                        },
                                    }}
                                />
                            </FormControl>

                            {/* Country  */}
                            <Typography variant="h6" fontWeight="400" textAlign={'left'}>
                                Country
                            </Typography>
                            <FormControl
                                fullWidth
                                sx={{
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    '&:hover': {
                                        boxShadow: '0 0 5px #ec1c24',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            >
                                <TextField
                                    id="Country"
                                    name={'country'}
                                    value={formik.values.country}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.country && Boolean(formik.errors.country)}
                                    helperText={formik.touched.country && formik.errors.country}
                                    fullWidth
                                    placeholder="Country"
                                    variant="outlined"
                                    sx={{
                                        transition: 'box-shadow 0.3s ease-in-out',
                                        width: '100%', // Adjust width to be smaller
                                        '& .MuiOutlinedInput-input': {
                                            padding: '10px 14px', // Adjust padding to reduce height
                                        },
                                    }}
                                />
                            </FormControl>
                            {/* Add more form controls here as needed */}
                            <Typography variant="h6" fontWeight="400" textAlign={'left'}>
                                Message
                            </Typography>
                            <FormControl fullWidth>
                                <TextField
                                    fullWidth
                                    multiline
                                    name={'message'}
                                    value={formik.values.message}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.message && Boolean(formik.errors.message)}
                                    helperText={formik.touched.message && formik.errors.message}
                                    variant="outlined"
                                    placeholder="Enter any additional information here"
                                    minRows={5} // This ensures the TextField has a minimum height to fit 5 rows.
                                    sx={{
                                        transition: 'box-shadow 0.3s ease-in-out',
                                        '&:hover': {
                                            boxShadow: '0 0 5px #ec1c24',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                        },
                                    }}
                                />
                            </FormControl>
                            <Button
                                type={'submit'}
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    backgroundColor: '#2DBE60',
                                    color: 'white',
                                    textTransform: 'none',
                                    fontSize: '1.25rem', // Bigger text size
                                    height: '56px', // Increase height
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Drop shadow for the button
                                    paddingX: '32px', // Horizontal padding
                                }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Container>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default GetInTouch;
