import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { FormControl, FormHelperText } from '@mui/material';

 

const PhoneISOSelect = ({ phoneISOs, phoneISO, errors, handlePhoneISOChange }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const options = phoneISOs.map((iso) => ({
    value: iso.text,
    label: `${iso.country} ${iso.text}`,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '56px',
      boxShadow: '0 0 1px black',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // To ensure the menu appears above other elements
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
    }),
  };

  const customComponents = {
    DropdownIndicator: null,
    IndicatorSeparator: null,
    MenuList: (props) => (
      <components.MenuList {...props}>
        <div style={{ padding: 8 }}>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              width: '100%',
              padding: '8px',
              boxSizing: 'border-box',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          />
        </div>
        {props.children}
      </components.MenuList>
    ),
  };

  return (
    <FormControl style={{ minWidth: "10vw" }} error={!!errors.phoneISO}>
      <Select
        value={options.find(option => option.value === phoneISO)}
        onChange={(selectedOption) => handlePhoneISOChange({ target: { value: selectedOption.value } })}
        options={options.filter((option) =>
          option.label.toLowerCase().includes(searchTerm.toLowerCase())
        )}
        placeholder="Select country code"
        styles={customStyles}
        components={customComponents}
        sx={{
            transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
          boxShadow: '0 0 5px #ec1c24',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', // Remove border on hover
          },
        },
        height: '56px', // Match the TextField height, adjust based on your theme
        marginRight: '-14px', // Adjust based on your needs to make Select flush with TextField
        '& .MuiSelect-select': {
          paddingRight: '30px', // Adjust this value based on the actual width of the dropdown arrow to center the text
        },
        '& fieldset': {
          borderRight: 0, // Remove the right border to merge visually with TextField
        },
      }}
      />
      {errors.phoneISO && <FormHelperText>{errors.phoneISO}</FormHelperText>}
    </FormControl>
  );
};

export default PhoneISOSelect;
