import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const EmailVerificationGuard = ({ children }) => {
    const user = useSelector(state => state.auth.user);
    const navigate = useNavigate();

    if (user) {
        navigate('/customer');
    }

    return children;
};
