import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, InputAdornment, FormControl, Box, IconButton } from '@mui/material';
import CustomButtons from './CustomButtons';
import BiggerButton from './BiggerButtons';
// import Story from '../../components/Story';
import CustomReviews from './CustomReviews3d';
// import Reviews from './Reviews';
import FAQ from './FAQ';
import GetInTouch from './GetInTouch';
import { useNavigate, useLocation } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpressNavbar from '../expressNavbar';
import BannerCarousel from '../../components/Banner';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import FAQs from '../homepage/FAQs';
// import Review from './NewReview';
const Express = () => {
    const [consignmentNo, setConsignmentNo] = useState('');
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();
    const [expandedCourier, setExpanded] = useState(false);
    const [expandedLuggagePlus, setLuggageExpanded] = useState(false);

    const handleToggleExpand = () => {
        setExpanded(!expandedCourier);
    };

    const handleToggleLuggageExpand = () => {
        setLuggageExpanded(!expandedLuggagePlus);
    };

    const handleTrackClick = () => {
        if (consignmentNo) {
            navigate(`/tracking/${consignmentNo}`);
        }
    };

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            // Ensure the element exists and is not hidden
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                // Use setTimeout to allow page layout to stabilize before scrolling
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }, 0);
            }
        }
    }, [location.hash]);

    const handleAcceptConsent = () => {
        const consentInfo = {
            consentGiven: true,
            timestamp: new Date().toISOString(),
        };

        fetch(`${process.env.REACT_APP_API_URL}/auth/record-consent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(consentInfo),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Consent recorded:', data);
            })
            .catch(error => {
                console.error('Error recording consent:', error);
            });
    };

    return (
        <>
            <ExpressNavbar />
            <BannerCarousel />
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '50vh' }}>
                <Box
                    sx={
                        {
                            // backgroundImage: 'url("/assets/expressBackground.jpg")',
                            // backgroundSize: 'cover',
                            // backgroundRepeat: 'no-repeat',
                            // backgroundPosition: 'center',
                        }
                    }
                >
                    <Container
                        maxWidth="lg"
                        sx={{
                            marginTop: isMobileOrTablet ? '2vh' : '10vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <CookieConsent
                            debug={false}
                            enableDeclineButton
                            location="bottom"
                            buttonText="Accept"
                            declineButtonText="Decline"
                            cookieName="myAppCookieConsent"
                            style={{ background: '#69727d' }}
                            buttonStyle={{ backgroundColor: '#528aae', color: 'white', fontSize: '13px' }}
                            expires={150}
                            onAccept={handleAcceptConsent}
                        >
                            We are using cookies to give you the best experience on our website. You can find out more
                            about which cookies we're using or switch them off in settings.
                        </CookieConsent>
                    </Container>
                </Box>
                <Container
                    maxWidth="lg"
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                >
                    <CustomButtons />
                    <BiggerButton />
                </Container>
                {/* <Box id="aboutSection">
      <Story  />
      </Box> */}
                <Container id="reviewsSection" maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        variant="h1"
                        sx={{
                            fontWeight: 400,
                            textAlign: 'center',
                            marginBottom: '2px',
                            marginTop: '5vh',
                            ml: 2,
                            color: '#2f3135',
                        }}
                    >
                        Reviews
                    </Typography>
                    {/* <Review /> */}
                    <CustomReviews />
                    {/* <Reviews /> */}
                    <Typography
                        variant="h1"
                        sx={{ fontWeight: 400, marginY: '5vh', color: '#2f3135', textAlign: 'center' }}
                    >
                        {' '}
                        Frequently Asked Questions{' '}
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            minHeight: 100,
                            bgcolor: 'grey.200',
                            borderRadius: 5,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: expandedCourier ? 3 : 0,
                            }}
                            onClick={handleToggleExpand}
                        >
                            <IconButton>
                                <ArrowForwardIosSharpIcon
                                    sx={{
                                        transform: expandedCourier ? 'rotate(90deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s',
                                    }}
                                />
                            </IconButton>
                            <Typography
                                variant="h1"
                                component="h1"
                                sx={{ color: '#2f3135', fontSize: isMobileOrTablet ? '26px' : 'h1' }}
                            >
                                Courier FAQs
                            </Typography>
                        </Box>
                        {expandedCourier && (
                            <>
                                <Box sx={{ width: '95%', ml: 'auto', mr: 'auto', my: 2 }}>
                                    <FAQ />
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            minHeight: 100,
                            bgcolor: 'grey.200',
                            mt: 2,
                            borderRadius: 5,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: expandedLuggagePlus ? 3 : 0,
                            }}
                            onClick={handleToggleLuggageExpand}
                        >
                            <IconButton>
                                <ArrowForwardIosSharpIcon
                                    sx={{
                                        transform: expandedLuggagePlus ? 'rotate(90deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s',
                                    }}
                                />
                            </IconButton>
                            <Typography
                                variant="h1"
                                component="h1"
                                sx={{ color: '#2f3135', fontSize: isMobileOrTablet ? '22px' : 'h2' }}
                            >
                                TCS Express Boxes FAQs
                            </Typography>
                        </Box>
                        {expandedLuggagePlus && (
                            <>
                                <Box sx={{ width: '95%', ml: 'auto', mr: 'auto', my: 2 }}>
                                    <FAQs />
                                </Box>
                            </>
                        )}
                    </Box>
                    <Typography
                        id="contactSection"
                        variant="h1"
                        sx={{ fontWeight: 400, textAlign: 'center', marginTop: '5vh', color: '#2f3135' }}
                    >
                        Need Assistance?
                    </Typography>

                    <GetInTouch />
                </Container>
            </Box>
        </>
    );
};

export default Express;
