import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Container,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { navbarLink, ROOT } from '../../constants/navbarLink';

const ExpressNavbar = () => {
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const navigate = useNavigate(); // Hook to programmatically navigate

    const handleDrawerToggle = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    // Function to handle navigation or scrolling
    const handleNavigationOrScroll = (e, link) => {
        e.preventDefault();

        navigate(link.to);

        // Directly scroll to the section if already on the home page
        const element = document.getElementById(link.scrollId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Container maxWidth="lg" disableGutters={true}>
            <AppBar sx={{ zIndex: theme.zIndex.drawer + 1 }} position="static" color="transparent" elevation={0}>
                <Toolbar>
                    <a onClick={e => handleNavigationOrScroll(e, ROOT)} style={{ cursor: 'pointer' }}>
                        <img src="/assets/TCS-logo.png" alt="TCS" style={{ height: isMobileOrTablet ? 20 : '50px' }} />
                    </a>
                    <div style={{ flexGrow: 1 }} />
                    {isMobileOrTablet ? (
                        <>
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                sx={{ display: { md: 'none' } }}
                                onClick={handleDrawerToggle}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Drawer
                                anchor="right"
                                open={mobileMenuOpen}
                                onClose={handleDrawerToggle}
                                sx={{ display: { md: 'none' } }}
                            >
                                <List>
                                    {Object.values(navbarLink).map((link, index) => (
                                        <a
                                            key={index}
                                            href={link.to}
                                            onClick={e => handleNavigationOrScroll(e, link)}
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                        >
                                            <ListItem button>
                                                <ListItemText primary={link.title} />
                                            </ListItem>
                                        </a>
                                    ))}
                                </List>
                            </Drawer>
                        </>
                    ) : (
                        Object.values(navbarLink).map((link, index) => (
                            <a
                                key={index}
                                href={link.to}
                                onClick={e => handleNavigationOrScroll(e, link)}
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                <Button
                                    color="inherit"
                                    style={{
                                        textTransform: 'none',
                                        fontSize: isMobileOrTablet ? '10px' : '16px',
                                        color: '#2f3135',
                                    }}
                                >
                                    {link.title}
                                </Button>
                            </a>
                        ))
                    )}
                </Toolbar>
            </AppBar>
        </Container>
    );
};

export default ExpressNavbar;
