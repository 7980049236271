import React, { useState } from 'react';
import { Box, Card, CardContent, TextField, Button, Alert, Typography, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setUser, setToken, setOrders } from '../../state';
import { customerLoginMode, customerLoginModeValidation } from '../../constants/customerLoginMode';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MuiTelInput } from 'mui-tel-input';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

function LoginCustomer() {
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState(customerLoginMode.LOGIN); // 'login', 'register', or 'reset'
    const dispatch = useDispatch();
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            phone: '',
            fullName: '',
        },
        validationSchema: customerLoginModeValidation[mode],
        validateOnChange: true,
    });

    const handleSubmit = async e => {
        e.preventDefault();

        const { email, password, phone, fullName } = formik.values;

        let url = `${process.env.REACT_APP_API_URL}/customer/${mode.toLowerCase()}`;
        let payload = {};

        switch (mode) {
            case customerLoginMode.LOGIN:
                payload = { email, password };
                break;
            case customerLoginMode.REGISTER:
                payload = { fullName, email, phone, password };
                break;
            case customerLoginMode.RESET:
                payload = { email };
                break;
            default:
                return;
        }

        const isEmpty = Object.values(payload).some(value => !value);

        if (isEmpty || !formik.isValid) {
            Object.keys(payload).map(key => formik.setFieldTouched(key));
            return;
        }

        try {
            setLoading(true);
            const { data } = await axios.post(url, payload);

            if (data.token) {
                setMessage('Success');
                dispatch(setUser({ user: data.user }));
                dispatch(setToken({ token: data.token }));
                dispatch(setOrders({ orders: data.user.orders }));
            } else if (mode === customerLoginMode.RESET) {
                setShowAlert(true);
            }

            // setMessage(data.msg);
            switch (mode) {
                case customerLoginMode.LOGIN:
                    break;
                case customerLoginMode.REGISTER: {
                    MySwal.fire({
                        title: 'Registration successfull!',
                        text: 'We sent you a verification email, please check your inbox for the next stemps.',
                        icon: 'success',
                    });
                    setMode(customerLoginMode.LOGIN);
                }
                case customerLoginMode.RESET:
                    break;
                default:
                    return;
            }
        } catch (error) {
            setMessage(error.response.data.msg);
            console.error(`${mode} error:`, error.response.data.msg);
        } finally {
            setLoading(false);
        }
    };

    const toggleMode = () => {
        setMode(prevMode => {
            if (prevMode === customerLoginMode.LOGIN) {
                return customerLoginMode.REGISTER;
            }
            return customerLoginMode.LOGIN;
        });

        setMessage('');
        formik.resetForm();
    };

    const toggleResetMode = () => {
        setMode(prevMode => (prevMode === customerLoginMode.RESET ? customerLoginMode.LOGIN : customerLoginMode.RESET));
        setMessage('');
        formik.resetForm();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > :not(style)': { m: 1 },
            }}
        >
            <Card variant="outlined" sx={{ borderColor: '#ec1c24', borderWidth: 2 }}>
                <CardContent>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            p: 3,
                            '& .MuiTextField-root': { m: 1, width: '35ch' },
                        }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <Typography variant="h3" sx={{ textAlign: 'center', mb: 2 }}>
                            {mode.charAt(0).toUpperCase() + mode.slice(1)} {/* Capitalize the first letter */}
                        </Typography>
                        {mode === customerLoginMode.REGISTER && (
                            <>
                                <TextField
                                    id="fullName"
                                    label="Full Name"
                                    variant="outlined"
                                    fullWidth
                                    value={formik.values.fullName}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                                    helperText={formik.touched.fullName && formik.errors.fullName}
                                />
                                <MuiTelInput
                                    id="phone"
                                    label="Phone"
                                    variant="outlined"
                                    fullWidth
                                    defaultCountry={'GB'}
                                    value={formik.values.phone}
                                    onBlur={formik.handleBlur}
                                    onChange={phone => formik.setFieldValue('phone', phone)}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                />
                            </>
                        )}
                        <TextField
                            id="email"
                            label="Email"
                            type="email"
                            variant="outlined"
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            fullWidth
                        />
                        {mode !== customerLoginMode.RESET && (
                            <TextField
                                id="password"
                                label="Password"
                                type="password"
                                variant="outlined"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                fullWidth
                            />
                        )}

                        {message && (
                            <Typography sx={{ width: '280px', mx: 1 }} color="error">
                                {message}
                            </Typography>
                        )}

                        <Button
                            disabled={loading}
                            variant="contained"
                            type="submit"
                            sx={{
                                backgroundColor: '#ec1c24',
                                color: 'white',
                                '&:hover': { backgroundColor: '#b7181f' },
                                mt: 2,
                                mb: 1,
                            }}
                            fullWidth
                        >
                            {loading ? (
                                <CircularProgress size={28} color={'error'} />
                            ) : mode === customerLoginMode.RESET ? (
                                'Send Reset Link'
                            ) : mode === customerLoginMode.LOGIN ? (
                                'Login'
                            ) : (
                                'Register'
                            )}
                        </Button>
                        {mode !== customerLoginMode.RESET && (
                            <Button onClick={toggleMode} sx={{ textTransform: 'none', justifyContent: 'center' }}>
                                {mode === customerLoginMode.LOGIN
                                    ? "Don't have an account?"
                                    : 'Already have an account?'}
                            </Button>
                        )}
                        {mode !== customerLoginMode.REGISTER && (
                            <Button onClick={toggleResetMode} sx={{ textTransform: 'none', justifyContent: 'center' }}>
                                {mode === customerLoginMode.RESET ? 'Back to login' : 'Forgot password?'}
                            </Button>
                        )}
                    </Box>
                </CardContent>
            </Card>
            {showAlert && (
                <Alert severity="success" sx={{ mb: 2 }}>
                    A link to reset your password has been sent to your email.
                </Alert>
            )}
        </Box>
    );
}

export default LoginCustomer;
