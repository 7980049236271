 

import React from 'react';
import { Box, Typography } from '@mui/material';
import Story from '../../components/Story';
 
 
const About = () => {
  
  return (
    <Box id="aboutSection" sx={{mt: "5vh"}}>
      <Story  />
      </Box>
  );
};

export default About;
